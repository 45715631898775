import * as React from "react"
import Layout from "../../components/layout"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Helmet from 'react-helmet'

const heightMax = {
	maxHeight: "2466px",
	overflow: "hidden",
}


const promentor = () => {
  return (
  <>
<Helmet
    bodyAttributes={{
        class: 'single-page whitemain'
    }}
/>
  <Layout pageTitle="qvm">
    <section id="main">
      <div id="worktitle">
        <span>
          <h1>Promentor</h1>
          <span className="highlight"></span>
        </span>
        <span>Highly Qualified Consulting Teams</span>
      </div>
    </section>

    <section id="work">
      <div className="work">
          <div className="picture workpicture">
		  	<img src={'/img/works/promentor.jpg'} alt="Sweeney Interpreting" />

          </div>

        <div className="seeWork">
          <div className="seeWork__content">
            <div className="icons">
              <img className="picture" src={'/img/single/wordpress-icon.png'} width="139" height="87" alt="Wordpress" />

            </div>
            <a className="button visitwebsite" href="https://promentor.com.au/" target="_blank"  rel="noreferrer"><span className="seemore"><i>&#xe801;</i></span>Visit Website</a>
          </div>
        </div>

      </div>
    </section>

    <section id="single-content">
      <div className="single-content">
        <p>All leaders recognise that efficiency, profitability and accountability are key to success. Not all leaders know how to make this a reality. Promentor do.</p>
        <p>Collaborating with designer to develop high quality customised WordPress responsive website for desktop, tablet and smartphone. Powered by WordPress with Advanced Custom Fields plugin and graphic animations.</p>
      </div>

      <div className="project-image">
        <div className="left-col">
          <img className="picture" src={'/img/single/promentor-desktop.jpg'} alt="Desktop View" />
        </div>
        <div className="right-col" style={heightMax}>
          <img className="picture" src={'/img/single/promentor-mobile.jpg'} alt="Mobile View" />
      </div>
      </div>
    </section>


    <section id="post-pagination">
      <div className="post-pagination">
        <div className="previous">
          <AniLink cover to="/work/asapd" bg="#00ccd4" direction="right" duration={0.76} className="button project prev">Prev Work</AniLink>
          <p className="project-name">ASAPD</p>
        </div>
        <div className="next">
          <AniLink cover to="/work/sports-excellence-scholarship-funds" bg="#00ccd4" direction="right" duration={0.76} className="button project next">Next Work</AniLink>
          <p className="project-name">Sports Excellence Scholarship Funds</p>
        </div>
      </div>
    </section>
</Layout>

  </>
  )
}

export default promentor


export const Head = () => (
  <>
    <title>Promentor - Tobstar</title>
<meta name="description" content="" />
  </>
)